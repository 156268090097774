import "../../util/mobile-table.css";
import Title from "antd/lib/typography/Title";
import {Table, Button, Space, notification, Row, Modal, Tooltip,} from "antd";
import  { useCallback, useContext, useEffect, useRef, useState } from "react";
import { ConfiguratorContext } from "../../context";
import dayjs from 'dayjs'
import { ArrayParam, BooleanParam, NumberParam, NumericArrayParam, StringParam, useQueryParam } from "use-query-params";
import Utils from "../../util/util";
import {
  Customer,
  DateFilterType,
  PAGINATION_MAX_PAGE_SIZE,
  PoNumber,
  QuoteFilter,
  QuoteStatus,
  ShippingDestination,
  SortDirection,
  Truck,
  User,
  RevisionApprovalStatus,
  AXIOS_CANCEL_MSG,
  DEFAULT_THROTTLE,
  RevisionType, ApprovalInfo,
} from "../../api/models";
import { ColumnType, FilterValue, SorterResult, TablePaginationConfig } from "antd/lib/table/interface";
import {debounce, throttle} from "lodash";
import {AsyncState, useAsyncState} from "../../hook/useAsyncState";
import { getCSVRow } from "../../helpers/csv";
import {Link} from "react-router-dom";
import Paragraph from "antd/es/typography/Paragraph";
import CategorySelector from "../../components/category_selector";
import axios, {CancelTokenSource} from "axios";
import QuoteFilterControls from "../../components/QuoteFilterControls";
import {TruckOutlined} from "@ant-design/icons";

type  ApprovalsReportDataSort = SorterResult<ApprovalsReportData> | SorterResult<ApprovalsReportData>[]
type  TableOnChangeFn = (p:TablePaginationConfig, f:Record<string, FilterValue | null>, s: ApprovalsReportDataSort) => void

export interface ApprovalsReportData {
quoteId                                : string
quoteName                              : string
revision                               : number
partNo                                 : string
epicorRevision                         : string
status                                 : string
reservation                            : boolean
revisionType                           : RevisionType
approvalStatus                         : RevisionApprovalStatus
modelName                              : string
fuelType                               : string
quantity                               : number
dealerPrice                            : number
endCustomer                            : Customer | undefined
owner                                  : User | undefined
dealerName                             : string
bmSalesReps?                           : string | undefined
latestApproval?                        : ApprovalInfo
quoteApprovalDate?                     : Date
orderApprovalDate?                     : Date
quoteExpirationDate?                   : Date
projectedProductionDate?               : Date
productionDate?                        : Date
projectedShipDate?                     : Date
shipDate?                              : Date
//projectedReadyToShip?                  : Date
//readyToShipDate?                       : Date
shippingDestination?                   : ShippingDestination
shippingDestinationPrice?              : number
poNumber?                              : PoNumber
incentivePrograms?                     : string
selectionSummary?                      : { [key: string]: string }, //todo
trucks?                                : Truck[]
gvwr?                                  : number
order: boolean,
cancelled: boolean,
archived: boolean,
expired: boolean,
}

interface ExportOptions {
  categories?: number[];
  columns: ColumnType<ApprovalsReportData>[];
  rowPerUnit?: boolean;
}

const infoTextStyle = {color: "blue", fontWeight: 'bold'};
const warningTextStyle = {color: "orange", fontWeight: 'bold'};
const errorTextStyle = {color: "red", fontWeight: 'bold'};
const formatQuoteDisplayStatus =  (q:ApprovalsReportData) =>
    [Utils.formatQuoteDisplayStatus({ ...q, shipped:!!q.shipDate, })]
        .map( ({major, minor}) => {
          const minorStyle =
              minor == "Approved" ? infoTextStyle
              : minor == "Pending" ? warningTextStyle
              : minor == "Rejected" ? errorTextStyle
              : undefined;
          return <>
            <Space direction={"vertical"}>
              <div>{major}</div>
              {minor && <div style={minorStyle}>({minor})</div>}
              {q.shipDate && <Tooltip title={`Shipped on ${dayjs(q.shipDate).format("M/DD/YY")}`}><TruckOutlined style={{fontSize: "1.2rem"}} /></Tooltip>}
            </Space>
          </>
        });

const DEFAULT_PAGE_SIZE = 10;
const ApprovalsReport = () => {

  const configurator = useContext(ConfiguratorContext);

  const [_quoteList, quoteListAsync] = useAsyncState<ApprovalsReportData[]>([]);
  const [incentiveProgramsParam, setIncentiveProgramsParam] = useQueryParam<string|undefined|null>("incentivePrograms", StringParam);
  const [searchFilterParam, setSearchFilterParam] = useQueryParam<string|undefined|null>("filter", StringParam);
  const [pageSizeQueryParam, setPageSizeQueryParam] = useQueryParam<number|undefined|null>("nr", NumberParam);
  const [currentPageParam, setCurrentPageParam] = useQueryParam<number|undefined|null>("p", NumberParam);
  const [dealerFilterParam, setDealerFilterParam] = useQueryParam<any>("dealer", ArrayParam);
  const [sortFieldQueryParam, setSortFieldQueryParam] = useQueryParam<string|undefined|null>("sf", StringParam);
  const [sortDirectionQueryParam, setSortDirectionQueryParam] = useQueryParam<string|undefined|null>("sd", StringParam);
  const [ordersOnlyParam, setOrdersOnlyParam] = useQueryParam<boolean|undefined|null>("ordersOnly", BooleanParam);
  const [hasCustomOptionsParam, setHasCustomOptionsParam] = useQueryParam<boolean|undefined|null>("hasCustomOptions", BooleanParam);
  const [dateFilterTypeParam, setDateFilterTypeParam] = useQueryParam<string|undefined|null>("dateFilterType", StringParam);
  const [dateFilterStartParam, setDateFilterStartParam] = useQueryParam<string|undefined|null>("dateFilterStart", StringParam);
  const [dateFilterEndParam, setDateFilterEndParam] = useQueryParam<string|undefined|null>("dateFilterEnd", StringParam);
  const [endCustomerParam, setEndCustomerParam] = useQueryParam("endCustomerIds", NumericArrayParam);
  const [shippingDestinationParam, setShippingDestinationParam] = useQueryParam("shippingDestinationIds", NumericArrayParam);
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [showExport, setShowExport] = useState<ExportOptions>();
  const [filter, setFilter] = useState<QuoteFilter>({
    myQuotes: false,
    includingArchived: false,
    search: searchFilterParam || undefined, //silly fix for null
    dealerLst: dealerFilterParam || undefined, //silly fix for null
    incentivePrograms: incentiveProgramsParam?.split(",") || undefined, //silly fix for null
    ordersOnly: ordersOnlyParam || false,
    includeReservations: ordersOnlyParam || false,
    hasCustomOptions: hasCustomOptionsParam || false,
    dateFilterType: dateFilterTypeParam as DateFilterType,
    dateFilterStart: dateFilterStartParam ? dayjs(dateFilterStartParam) : undefined,
    dateFilterEnd: dateFilterStartParam ? dayjs(dateFilterEndParam) : undefined,
    endCustomerId: endCustomerParam as number[] || undefined,
    shippingDestinationId: shippingDestinationParam as number[] || undefined,
  });
  const cancelTokenSourceRef = useRef<CancelTokenSource>();

  const [sort, setSort] = useState<ApprovalsReportDataSort>({
    columnKey: sortFieldQueryParam || 'quoteId',
    order: sortDirectionQueryParam === 'ascend' ? 'ascend' : 'descend' //for typescript
  });
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    total: 0,
    position: ['bottomLeft'],
    pageSize: pageSizeQueryParam == null || pageSizeQueryParam > 500 ? DEFAULT_PAGE_SIZE : pageSizeQueryParam,
    current: currentPageParam == null || currentPageParam < 1 ? 1 : currentPageParam,
  });

  useEffect(() => {
    setPageSizeQueryParam(pagination.pageSize);
    setCurrentPageParam(pagination.current);
    loadQuotes( quoteListAsync, pagination, filter , sort );
    return () => loadQuotes.cancel();
  }, [pagination.pageSize, pagination.current, sort ]);

  const loadQuotes = useCallback(throttle((quoteListAsync:AsyncState<ApprovalsReportData[]>,  pagination: TablePaginationConfig, filter: QuoteFilter | undefined, sorter:ApprovalsReportDataSort ) => {

    const sort = [sorter].flat().map( sorter => ({
      field: sorter.columnKey?.toString() || "quoteId",
      direction: ( sorter.order === 'ascend' ? 'asc' : 'desc') as SortDirection,
    }));

    if ( cancelTokenSourceRef.current ) {
      cancelTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelSource;

    quoteListAsync.setLoading();
    configurator.api.fetchApprovalsReport({
      ...filter,
      page: ( pagination.current || 1 ) - 1,
      size: pagination.pageSize || DEFAULT_PAGE_SIZE,
      sort
    }, cancelSource.token )
      .then( resp => {
        quoteListAsync.setDone( resp.data.content );
        setPagination({ ...pagination, total: resp.data.totalElements });
      },
        (e:any) => {
          const id = e.response?.data?.message || e.message ;
          if ( id !== AXIOS_CANCEL_MSG ) {
            quoteListAsync.setFail( e.message );
            notification.error({ message:"Quotes failed to load. " + e.message });
          }
        });
  }, DEFAULT_THROTTLE ), [] );

  const tableOnChange =  (pagination:TablePaginationConfig, _filters:Record<string, FilterValue | null>, sorter: SorterResult<ApprovalsReportData> | SorterResult<ApprovalsReportData>[]) => {
    setPagination(pagination);

    const firstSort = [ sorter ].flatMap(v => v)[0];
    setSortFieldQueryParam( firstSort.columnKey ? String( firstSort.columnKey ) : undefined );
    setSortDirectionQueryParam( firstSort.order );

    setSort(sorter);
  };

  const getFilterWithDateRange = (filter: QuoteFilter) => {
    return filter['dateRange'] ? {...filter, dateFilterStart: filter['dateRange'][0], dateFilterEnd: filter['dateRange'][1]} : filter;
  }

  const onFilterChange = debounce( (_values: Record<string, any>, filter: QuoteFilter) => {
    filter = getFilterWithDateRange(filter);
    setSearchFilterParam(filter.search);
    setDealerFilterParam( filter.dealerLst );
    setIncentiveProgramsParam(filter.incentivePrograms?.join(","));
    setOrdersOnlyParam(filter.ordersOnly);
    setHasCustomOptionsParam(filter.hasCustomOptions);
    filter.dateFilterType && setDateFilterTypeParam(filter.dateFilterType);
    filter['dateRange'] && setDateFilterStartParam((filter['dateRange']?.[0]).toString());
    filter['dateRange'] && setDateFilterEndParam((filter['dateRange']?.[1]).toString());
    !!filter.endCustomerId?.length && setEndCustomerParam(filter.endCustomerId);
    !!filter.shippingDestinationId?.length && setShippingDestinationParam(filter.shippingDestinationId);
    filter.includeReservations = filter.ordersOnly;
    setPagination({ ...pagination, current: 1 });
    loadQuotes( quoteListAsync, pagination, filter , sort );
    setFilter(filter);
  }, 300);

  const assignTruckStatus = (orderData: OrderData, truckData: TruckData | undefined) => {
    const orderStatus = orderData.status === QuoteStatus.SHIPPED
      ?Utils.snakeCaseToFirstLetterCapitalized(QuoteStatus.ORDER.toString())
      :Utils.snakeCaseToFirstLetterCapitalized(orderData.status);
    
    // Must check order ship date to make sure truck is shipped
    const truckStatus = (truckData?.epicorProductionStatus.toLocaleLowerCase() === QuoteStatus.SHIPPED.toLocaleLowerCase())
      ? (!!truckData.shipDate ? Utils.snakeCaseToFirstLetterCapitalized(QuoteStatus.SHIPPED) : orderStatus)
      : truckData?.epicorProductionStatus;
    orderData.status = truckStatus || orderStatus;
  }

  interface TruckData {
    truckSerialNumberStr: string, 
    engineSerialNumber: string,
    transmissionSerialNumber: string, 
    vin: string, 
    epicorShipDate: string,
    epicorProductionDate: string,
    epicorProductionStatus: string,
    shipped: boolean,
    steerAxle1:string;
    steerAxle2:string;
    steerAxle3:string;
    pusherAxle1:string;
    driveAxle1:string;
    driveAxle2:string;
    driveAxle3:string;
    tagAxle:string;
    scr:string;
    dpf:string;
    projectedProductionDate:string;
    productionDate:string;
    projectedShipDate:string;
    shipDate:string;
  }

  interface OrderData {
    quoteId: string,
    quoteName: string,
    revision: string,
    partNo: string,
    epicorRevision: string,
    status: string,
    modelName: string,
    quantity: string,
    dealerPrice: string,
    poAmount: string,
    poNumber: string,
    hasDocuments: string,
    incentivePrograms: string,
    endCustomerName: string,
    ownerName: string,
    dealerName: string,
    bmSalesReps: string,
    quoteApprovalDate: string,
    orderApprovalDate:string,
    quoteExpirationDate:string,
    projectedProductionDate:string,
    productionDate:string,
    projectedShipDate:string,
    shipDate:string,
    //projectedReadyToShip:string,
    //readyToShipDate:string,
    shippingDestinationName: string,
    shippingDestinationPrice: string,
    truckSn: string,
    gvwr: string,
    fuelType: string,
  }

  const getTruckData = (truck: Truck | undefined, rowPerUnit: boolean | undefined): TruckData | undefined => {

    if (!rowPerUnit || !truck) return undefined;

    return {
      truckSerialNumberStr: truck.truckSerialNumberStr || "", 
      engineSerialNumber: truck.engineSerialNumber || "",
      steerAxle1: truck.steerAxle1 || "",
      steerAxle2: truck.steerAxle2 || "",
      steerAxle3: truck.steerAxle3 || "",
      pusherAxle1: truck.pusherAxle1 || "",
      driveAxle1: truck.driveAxle1 || "",
      driveAxle2: truck.driveAxle2 || "",
      driveAxle3: truck.driveAxle3 || "",
      tagAxle: truck.tagAxle || "",
      scr: truck.scr || "",
      dpf: truck.dpf || "",
      transmissionSerialNumber: truck.transmissionSerialNumber || "", 
      vin: truck.vin||'',
      projectedShipDate: truck.projectedShipDate ? dayjs(truck.projectedShipDate ).format("MM/DD/YYYY") : "",
      shipDate: truck.shipDate ? dayjs(truck.shipDate ).format("MM/DD/YYYY") : "",
      projectedProductionDate: truck.projectedProductionDate ? dayjs(truck.projectedProductionDate ).format("MM/DD/YYYY") : "",
      productionDate: truck.productionDate ? dayjs(truck.productionDate ).format("MM/DD/YYYY") : "",
      shipped: truck.shipped || "",
      epicorShipDate: truck.epicorShipDate || "",
      epicorProductionDate: truck.epicorProductionDate || "", 
      epicorProductionStatus: truck.epicorProductionStatus || "",
    } as TruckData;
  }

  // Assign column order
  const getTruckRowValue = (truckData: TruckData | undefined): string[] =>{
    return [
      truckData?.truckSerialNumberStr,
      truckData?.engineSerialNumber,
      truckData?.transmissionSerialNumber,
      truckData?.steerAxle1,
      truckData?.steerAxle2,
      truckData?.steerAxle3,
      truckData?.pusherAxle1,
      truckData?.driveAxle1,
      truckData?.driveAxle2,
      truckData?.driveAxle3,
      truckData?.tagAxle,
      truckData?.scr,
      truckData?.dpf,
      truckData?.vin,
      truckData?.projectedShipDate,
      truckData?.shipDate,
      truckData?.projectedProductionDate,
      truckData?.productionDate,
    ]
      .map( s => s || "" );
  }

  const getQuoteData = (q: ApprovalsReportData): OrderData => {
    const truckSn = Utils.buildSerialNumberStr(q.trucks?.map(t => t.truckSerialNumberStr)) || "";
    const formatQuoteDisplayStatus =  (q:ApprovalsReportData):string =>
        [Utils.formatQuoteDisplayStatus({ ...q, shipped:!!q.shipDate, })]
            .map(({major, minor}) => [major, minor]).flat()
            .filter(v=>v).join(" - ");

    return {
      quoteId: q.quoteId || "",
      quoteName: q.quoteName || "",
      revision: String(q.revision),
      partNo: q.partNo || "",
      epicorRevision: q.epicorRevision || "",
      status: formatQuoteDisplayStatus(q),
      modelName: q.modelName || "",
      quantity: String(q.quantity),
      dealerPrice: Utils.formatMoney(q.dealerPrice),
      poAmount: Utils.formatMoney(q.poNumber?.amount),
      poNumber: q.poNumber?.poNumber || "",
      hasDocuments: String(!!q?.poNumber?.documents?.length),
      incentivePrograms: q.incentivePrograms || "",
      endCustomerName: q.endCustomer?.name || "",
      ownerName: q.owner?.name || "",
      dealerName: q.dealerName || "",
      bmSalesReps: q.bmSalesReps || "",
      quoteApprovalDate:q?.quoteApprovalDate ? dayjs(q.quoteApprovalDate).format("MM/DD/YYYY") : "",
      orderApprovalDate:q?.orderApprovalDate ? dayjs(q.orderApprovalDate).format("MM/DD/YYYY") : "",
      quoteExpirationDate:q?.quoteExpirationDate ? dayjs(q.quoteExpirationDate).format("MM/DD/YYYY") : "",
      projectedProductionDate:q?.projectedProductionDate ? dayjs(q.projectedProductionDate).format("MM/DD/YYYY") : "",
      productionDate:q?.productionDate ? dayjs(q.productionDate).format("MM/DD/YYYY") : "",
      projectedShipDate:q?.projectedShipDate ? dayjs(q.projectedShipDate).format("MM/DD/YYYY") : "",
      shipDate:q?.shipDate ? dayjs(q.shipDate).format("MM/DD/YYYY") : "",
      //projectedReadyToShip:q?.projectedReadyToShip ? dayjs(q.projectedReadyToShip).format("MM/DD/YYYY") : "",
      //readyToShipDate:q?.readyToShipDate ? dayjs(q.readyToShipDate).format("MM/DD/YYYY") : "",
      shippingDestinationName: q.shippingDestination?.name || "",
      shippingDestinationPrice: Utils.formatMoney(q.shippingDestinationPrice),
      truckSn,
      gvwr: q.gvwr ? String(q.gvwr) : "",
      fuelType: q.fuelType,
    };
  }

  // Assign column order
  const getOrderRowValue = (processedQuoteData: OrderData, selections: string[]): string[] => {
    return [
      processedQuoteData.quoteId,
      processedQuoteData.quoteName,
      processedQuoteData.revision,
      processedQuoteData.partNo,
      processedQuoteData.epicorRevision,
      processedQuoteData.status,
      processedQuoteData.modelName,
      processedQuoteData.quantity,
      processedQuoteData.dealerPrice,
      processedQuoteData.poAmount,
      processedQuoteData.poNumber,
      processedQuoteData.hasDocuments,
      processedQuoteData.incentivePrograms,
      processedQuoteData.endCustomerName,
      processedQuoteData.ownerName,
      processedQuoteData.dealerName,
      processedQuoteData.bmSalesReps,
      processedQuoteData.quoteApprovalDate,
      processedQuoteData.orderApprovalDate,
      processedQuoteData.quoteExpirationDate,
      processedQuoteData.productionDate,
      processedQuoteData.shipDate,
      //processedQuoteData.projectedReadyToShip,
      //processedQuoteData.readyToShipDate,
      processedQuoteData.shippingDestinationName,
      processedQuoteData.shippingDestinationPrice,
      processedQuoteData.truckSn,
      processedQuoteData.gvwr,
      processedQuoteData.fuelType,
    ].concat(selections);
  }
  
  const processAndMapDataInOrder = (q: ApprovalsReportData, categoryColumns: string[], retCsv: string[]) => {

    const selections: string[] = categoryColumns.map(category => q.selectionSummary?.[category] || '');

    // show order only
    if (!showExport?.rowPerUnit) {
      const processedOrderData = getQuoteData(q);
      const orderRowValue = getOrderRowValue(processedOrderData, selections);
      retCsv.push(getCSVRow(orderRowValue));
    }
    // show truck and order
    else {

      const trucks = q.trucks?.length ? q.trucks : [{}] as Truck[];
      trucks
        .map(t => getTruckData(t, showExport?.rowPerUnit))
        .forEach(td => {

            const processedOrderData = getQuoteData(q);
            assignTruckStatus(processedOrderData, td); // Update status based on truck shipped or not
            const orderRowValue = getOrderRowValue(processedOrderData, selections);
            const truckRowValue = getTruckRowValue(td);
            const rowData = truckRowValue.concat(orderRowValue);
            retCsv.push(getCSVRow(rowData));
          })
    }
  }


  const getRowValue = (data: ApprovalsReportData[], columns:ColumnType<ApprovalsReportData>[]): string[] => {
    const categoryColumns: Array<string> = Array.from(
      data.reduce((categories, r) => {
        if (r.selectionSummary) {
          Object.keys(r.selectionSummary).forEach(k => categories.add(k));
        }
        return categories;
      }, new Set<string>())
    ).sort();

    const truckColumns = showExport?.rowPerUnit ? ['Unit Number', "Engine Serial Number", "Transmission Serial Number", 'Steer Axle 1', 'Steer Axle 2', 'Steer Axle 3', 
      'Pusher Axle 1', 'Drive Axle 1', 'Drive Axle 2', 'Drive Axle 3', 'Tag Axle', 'SCR / CARB', 'DPF', 'VIN', 'Target Ship Date', 'Ship Date', 'Target Production Date', 'Production Date'] : [];

    const csv = [truckColumns.concat(columns.map( c => c.title?.toString() || '' ).concat(categoryColumns)).join(',')];

    data.forEach((q: ApprovalsReportData) => {
      processAndMapDataInOrder(q, categoryColumns, csv);
    });

    return csv;
  }

  const exportReport = async (columns:ColumnType<ApprovalsReportData>[]) => {
    try {
      const sorter = [sort].flat().map( sorter => ({
        field: sorter.columnKey?.toString() || "quoteId",
        direction: ( sorter.order === 'ascend' ? 'asc' : 'desc') as SortDirection,
      }));

      setIsExporting(true);
      const resp = (await configurator.api.fetchApprovalsReport({
        ...filter,
        page: 0,
        size: PAGINATION_MAX_PAGE_SIZE, 
        sort: sorter,
        includeSelectionSummary: true,
        selectionCategories: showExport?.categories
      })).data;

      const csv = getRowValue(resp.content, columns);

      var blob = new Blob([csv.join('\n')], {type: 'text/csv;charset=utf-8'});
      var url = URL.createObjectURL(blob);
      var a = document.createElement('a');
      a.href = url;
      a.download = 'approval-report-' + (new Date()) + '.csv';
      document.body.appendChild(a);
      a.click();
    }
    finally {
      setIsExporting(false);
      setShowExport(undefined);
    }
  };

  const resetQueryParam = () => {
    !!searchFilterParam && setSearchFilterParam(undefined);
    !!dealerFilterParam?.length && setDealerFilterParam( undefined );
    !!ordersOnlyParam && setOrdersOnlyParam(undefined);
    !!hasCustomOptionsParam && setHasCustomOptionsParam(undefined);
    !!incentiveProgramsParam && setIncentiveProgramsParam(undefined);
    !!dateFilterTypeParam && setDateFilterTypeParam(undefined);
    !!dateFilterStartParam && setDateFilterStartParam(undefined);
    !!dateFilterEndParam && setDateFilterEndParam(undefined);
    !!endCustomerParam?.length && setEndCustomerParam(undefined);
    !!shippingDestinationParam?.length && setShippingDestinationParam(undefined);
  }

  return (
    <div className="site-layout-background">
      <Title level={2}>Approvals Report</Title>
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <QuoteFilterControls
          filter={filter}
          onFilterChange={onFilterChange}
          resetQueryParam={resetQueryParam}
        />
        <DesktopTable
          quoteListAsync={quoteListAsync}
          tableOnChange={tableOnChange}
          pagination={pagination}
          exportOrders={(c) => setShowExport({columns: c, rowPerUnit: true})}
        />
      </Space>
      {showExport && 
        <Modal open={true} okText="Export" confirmLoading={isExporting}
          onOk={() => exportReport(showExport.columns)} onCancel={() => setShowExport(undefined)}>
          <Title level={3}>Export Approvals Report</Title>
          <Row>
              <Paragraph>Choose a list of categories below to include selections from in the CSV. By default, no selections will be included.</Paragraph>
          </Row>
          <Row>
              <strong>Row Per Unit: </strong> &nbsp;
              <input style={{display: 'inline'}} type="checkbox" checked={showExport?.rowPerUnit ?? true}  onChange={(e: any) => setShowExport({...showExport, rowPerUnit: e.target.checked})}/>
          </Row>
          <Row>
              <strong>Only Include Categories:</strong>
          </Row>
          <Row style={{marginTop: '25px'}}>
              <CategorySelector style={{ width: '100%' }} mode="multiple" onChange={(cat) => setShowExport({...showExport, categories: cat})} />
          </Row>
        </Modal >
        }
    </div>
  );
};

const DesktopTable = (props: {
  quoteListAsync: AsyncState<ApprovalsReportData[]>
  tableOnChange: TableOnChangeFn
  pagination: TablePaginationConfig
  exportOrders:(columns:ColumnType<ApprovalsReportData>[])=>void
}) => {
  const { quoteListAsync, tableOnChange, pagination, exportOrders, } = props;
  const quoteList = quoteListAsync.val;

  const columns:ColumnType<ApprovalsReportData>[] = [
    {
      title: "Quote",
      key: "quoteId",
      sorter: true,
      dataIndex: "quoteId",
      fixed: "left",
    },
    {
      title: "Name",
      key: "latestRevision.name",
      sorter: true,
      fixed: "left",
      render: (q) => <>
        <Link to={"/configurator/" + encodeURI(q.quoteId)} target="_blank">
          <div className="ellipsis" style={{maxWidth: "25rem"}}>{q.quoteName}</div>
        </Link>
      </>
    },
    {
      title: "Rev",
      fixed: "left",
      dataIndex: "revision"
    },
    {
      title: "Part No.",
      fixed: "left",
      dataIndex: "partNo"
    },
    {
      title: "Epicor Revision",
      dataIndex: "epicorRevision"
    },
    {
      title: "Status",
      key: "displayStatus",
      render: formatQuoteDisplayStatus
    },
    {
      title: "Model",
      sorter: true,
      key: "latestRevision.model.name",
      dataIndex: "modelName",
    },
    {
      title: "# Trucks",
      key: "latestRevision.quantity",
      sorter: true,
      dataIndex: "quantity",
    },
    {
      title: "Dealer Price",
      dataIndex: "dealerPrice",
      render: (q) => Utils.formatMoney( q ),
    },
    {
      title: "PO Value",
      key: "latestRevision.poNumber.amount",
      dataIndex: "poNumber",
      sorter: true,
      render: (q) => Utils.formatMoney( q?.amount )
    },
    {
      title: "PO Number",
      key: "latestRevision.poNumber.poNumber",
      dataIndex: "poNumber",
      sorter: true,
      render: (q) => q?.poNumber,
    },
    {
      title: "Has PO Documents",
      render: (q) => String( !!q?.poNumber?.documents?.length ),
    },
    {
      title: "Incentive Programs",
      dataIndex: "incentivePrograms",
    },
    {
      title: "End Customer",
      key: "latestRevision.endCustomer.name",
      dataIndex: "endCustomer",
      sorter: true,
      render: (q) => q?.name
    },
    {
      title: "Salesperson",
      key: "owner.name",
      sorter: true,
      dataIndex: "owner",
      render: (q) => q?.name
    },
    {
      title: "Dealer",
      key: "owner.dealer.name",
      sorter: true,
      dataIndex: "dealerName",
    },
    {
      title: "BM Sales",
      dataIndex: "bmSalesReps",
    },
    {
      title: "Quote Approval Date",
      render: (q) => <span>{q.quoteApprovalDate ? dayjs(q.quoteApprovalDate).format("M/D/YY h:mm a") : ""}</span>
    },
    {
      title: "Order Approval Date",
      render: (q) => <span>{q.orderApprovalDate ? dayjs(q.orderApprovalDate).format("M/D/YY h:mm a") : ""}</span>
    },
    {
      title: "Quote Expiration",
      dataIndex: "quoteExpirationDate",
      render: (q) => <span>{q ? dayjs(q).format("MM/DD/YYYY") : ""}</span>
    },
    {
      key: 'projectedProductionDate',
      title: 'Production Date',
      render: (t) => t.productionDate ? <span style={{color: "green"}}>{dayjs(t.productionDate).format('M/D/YY')}</span>
          : t.projectedProductionDate ? <span style={{color: "orange"}}>{dayjs(t.projectedProductionDate).format('M/D/YY')}</span>
              : <></>
    },
      /*
    {
      key: 'projectedReadyToShip',
      title: 'Ready to Ship Date',
      render: (t) => t.readyToShip ? <span style={{color: "green"}}>{dayjs(t.readyToShip).format('M/D/YYYY')}</span>
          : t.projectedReadyToShip ? <span style={{color: "orange"}}>{dayjs(t.projectedReadyToShip).format('M/D/YYYY')}</span>
              : <></>
    },
       */
    {
      key: 'projectedShipDate',
      title: 'Ship Date',
      render: (t) => t.shipDate ? <span style={{color: "green"}}>{dayjs(t.shipDate).format('M/D/YY')}</span>
          : t.projectedShipDate ? <span style={{color: "orange"}}>{dayjs(t.projectedShipDate).format('M/D/YY')}</span>
              : <></>
    },
    {
      title: "Destination",
      key: "latestRevision.shippingDestination.name",
      sorter: true,
      dataIndex: "shippingDestination",
      render: (q) => q?.name
    },
    {
      title: "Destination Charge",
      dataIndex: "shippingDestinationPrice",
      render: (q) => Utils.formatMoney(q)
    },
    {
      title: "Trucks",
      render: (q) => Utils.buildSerialNumberStr( q.trucks?.map(t => t.truckSerialNumberStr ) ) || ""
    },
    {
      title: "GVWR",
      dataIndex: "gvwr",
    },
    {
      title: "Fuel Type",
      dataIndex: "fuelType",
    },
  ];

  return <Space direction={"vertical"}>
    <div style={{display: "flex"}}>
      <Button type="primary" onClick={() => exportOrders(columns)}>Export</Button>
    </div>
    <Table
        loading={quoteListAsync.isLoading()}
        onChange={tableOnChange}
        bordered
        pagination={pagination}
        dataSource={quoteList}
        columns={columns}
        rowKey="quoteId"
    />
  </Space>
}



export default ApprovalsReport;
