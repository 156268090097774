import axios, { CancelTokenSource } from 'axios';
import { throttle } from 'lodash';
import { useCallback, useContext, useRef } from 'react';
import useSWR from 'swr';
import { ListQuotesResponse } from '../api';
import { AXIOS_CANCEL_MSG, DEFAULT_THROTTLE, Page, QuoteFilter, SortDirection } from '../api/models';
import { ConfiguratorContext } from '../context';
import { ListQuotesResponseSort } from '../pages/quotes';


interface QuoteListProps {
  filter?: QuoteFilter
  current?: number
  pageSize?:number,
  sorter?:ListQuotesResponseSort
}

export const useQuoteList = (props: QuoteListProps) => {

  const cancelTokenSourceRef = useRef<CancelTokenSource>();
  const configurator = useContext(ConfiguratorContext);

  const fetcher = useCallback(throttle(async ( props:QuoteListProps  ) : Promise<Page<ListQuotesResponse> | undefined> => {

    const { filter, current, pageSize, sorter } = props;

    const sort = [sorter].flat().map( sorter => ({
      field: sorter?.columnKey?.toString() || "updatedAt",
      direction: ( sorter?.order === 'ascend' ? 'asc' : 'desc') as SortDirection,
    }));

    if ( cancelTokenSourceRef.current ) {
      cancelTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelSource;

    try {
      const resp = await configurator.api.listQuotes({
        ...filter,
        page: (current || 1) - 1,
        size: pageSize || 20,
        sort,
      },
        cancelSource.token,
      )
      cancelTokenSourceRef.current = undefined;

      return resp.data;
    }
    catch(e: any) {
      const id = e.response?.data?.message || e.message ;
      if ( id === AXIOS_CANCEL_MSG ) return;
      throw e;
    }
  }, DEFAULT_THROTTLE), []);

  // Use SWR for data fetching
  return useSWR([
    'listQuotes',
    props
  ],
    ([_k, p]) => fetcher(p),
    {
      //maybe unnecessary
      revalidateOnFocus: false,
      //maybe unnecessary
      dedupingInterval:1 
    }
  );
};


export default useQuoteList;

